<template>
  <div class="my-service-detail">
    <!-- pc -->
    <div class="pc container" v-if="detail">
      <div class="h5 main" style="padding:60px 0 28px 0">내 서비스 상세정보</div>
      <card-my-service-pc v-if="detail" :card="detail" :detail="true"></card-my-service-pc>
      <div class="grid">
        <div class="panel-container"  style="grid-row:1 / span 2">
          <div class="flex-between" style="margin-bottom:24px">
            <div class="h7 main">결제정보</div>
            <div class="body4 sub3">* 결제와 관련된 사항은 FAQ에서 확인해주세요.</div>
          </div>
          <div class="box-monthly">
            <div class="flex" style="justify-content: space-between">
              <div class="h8">{{ datesFormat(detail.service_order.next_date, 'date_3') }} 결제 예정</div>
              <div><span class="price h7">{{detail.service_order.subscribe_price|currencyNum}}</span><span class="body2 gray">원<span class="body2-bold">/월</span></span></div>
            </div>
            <div class="body4-medium gray">{{serviceNames}}</div>
          </div>
          <div class="body5 sub3" style="margin-top:8px">*월 결제 비용은 디자인 수정 및 서비스 개설이 완료된 한달 이후부터 자동으로 결제 됩니다. </div>
          <div class="divider"></div>
          <div>
            <div v-for="(item,idx) in detail.service_order.products" :key="'account-'+idx" class="card-account">
              <div>
                <div class="h8 sub2">{{ item.category }}</div>
                <div class="body2 sub3">{{ item.name }}</div>
              </div>
              <div><span class="price h8 main">{{item.price|currencyNum}}</span><span class="body2 sub2">원<span v-if="item.is_subscription" class="body2-bold">/월</span></span></div>
            </div>
          </div>
          <button class="button is-gray" style="width:100%;margin-top:16px" @click="$refs.accountsModal.open()">결제 상세 내역</button>
        </div>
        <div class="panel-container">
          <div class="h7 main">개설 신청자 정보</div>
          <div class="divider"></div>
          <div class="h8 sub2" style="margin-top:16px">신청자</div>
          <div class="body2">{{ detail.service_order.name }}</div>
          <div class="divider-gray2"></div>
          <div class="h8 sub2" style="margin-top:16px">연락처</div>
          <div class="body2">{{ detail.service_order.phone }}</div>
          <div class="divider-gray2"></div>
          <div class="h8 sub2" style="margin-top:16px">이메일</div>
          <div class="body2">{{ detail.service_order.email }}</div>
        </div>
        <div class="panel-container">
          <div class="h7 main">결제수단</div>
          <div class="divider"></div>
          <div class="flex" style="margin-top:16px;justify-content: space-between">
            <div>
              <div class="h8 sub2">카드</div>
              <!--<div class="body2">{{ code[detail.service_order.bill.code] }} {{ detail.service_order.bill.num }}</div>-->
              <div class="body2">{{ detail.service_order.bill.card_name }} {{ detail.service_order.bill.num }}</div>
            </div>
            <button class="button is-gray" style="width:120px"  @click="vBill = true">변경</button>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile container" v-if="detail">
      <div class="h7 main" style="padding:24px 0 16px 0">내 서비스 상세정보</div>
      <card-my-service v-if="detail" :card="detail" :detail="true"></card-my-service>
      <div>
        <div class="panel-container">
          <div class="h8 main" style="margin-bottom:4px">결제정보</div>
          <div class="body6 sub3" style="margin-bottom:16px">* 결제와 관련된 사항은 FAQ에서 확인해주세요.</div>
          <div class="box-monthly">
            <div class="flex" style="justify-content: space-between">
              <div>
                <div class="body5-medium">{{ datesFormat(detail.service_order.next_date, 'date_3') }} 결제 예정</div>
              </div>
              <div><span class="price h8">{{detail.service_order.subscribe_price|currencyNum}}</span>
                <span class="gray" style="font-size:13px">원<span class="weight-700">/월</span></span>
              </div>
            </div>
            <div class="body5 gray" style="margin-top:4px">{{serviceNames}}</div>
          </div>
          <div class="body6 sub3" style="margin-top:8px">*월 결제 비용은 디자인 수정 및 서비스 개설이 완료된 한달 이후부터 자동으로 결제 됩니다. </div>
          <div class="divider"></div>
          <div>
            <div v-for="(item,idx) in detail.accounts" :key="'account-'+idx" class="card-account">
              <div>
                <div class="body5-medium sub2">{{ item.name }}</div>
                <div class="body5 sub3">{{ item.desc }}</div>
              </div>
              <div><span class="price h8 main">{{item.price|currencyNum}}</span><span class="body5 sub2">원<span class="body5-bold">/월</span></span></div>
            </div>
          </div>
          <button class="button is-gray body5-medium" style="width:100%;margin-top:16px"  @click="$refs.accountsModal.open()">결제 상세 내역</button>
        </div>
        <div class="panel-container">
          <div class="h8 main">개설 신청자 정보</div>
          <div class="divider"></div>
          <div class="body5-medium sub2" style="margin-top:16px">신청자</div>
          <div class="body5">{{ detail.service_order.name }}</div>
          <div class="divider-gray2"></div>
          <div class="body5-medium sub2" style="margin-top:16px">연락처</div>
          <div class="body5">{{ detail.service_order.phone }}</div>
          <div class="divider-gray2"></div>
          <div class="body5-medium sub2" style="margin-top:16px">이메일</div>
          <div class="body5">{{ detail.service_order.email }}</div>
        </div>
        <div class="panel-container">
          <div class="h8 main">결제수단</div>
          <div class="divider"></div>
          <div class="body5-medium sub2" style="margin-top:16px">카드</div>
          <div class="body5">{{ code[detail.service_order.bill.code] }} {{ detail.service_order.bill.num }}</div>
          <button class="button is-gray body5-medium" style="width:100%;margin-top:32px">변경</button>
        </div>
        <div class="flex-center">
          <button class="button is-gray" style="width:170px" @click="$router.back()">목록보기</button>
        </div>
      </div>
    </div>

    <user-billing-modal v-if="vBill" :radio="true" @close="vBill=false" @select="onSelectBill"></user-billing-modal>
    <sweet-modal overlay-theme="dark" ref="accountsModal" :width="564"
                 @open="onModal"
                 @close="offModal"
                 :hide-close-button="true">
      <my-account-list @close="$refs.accountsModal.close()"></my-account-list>
    </sweet-modal>
  </div>
</template>

<script>
  import CardMyService from "../component/CardMyService";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import CardMyServicePc from "../component/CardMyServicePc";
  import MyAccountList from "./MyAccountList";
  import { SweetModal } from 'sweet-modal-vue';
  import UserBillingModal from "../modal/UserBillingModal";
  export default {
    name: "MyServiceDetail",
    components: {UserBillingModal, MyAccountList, CardMyServicePc, CardMyService, SweetModal},
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        detail: undefined,
        serviceNames: '',
        code: {
          '01': '하나(외환)카드',
          '03': '롯데카드',
          '04': '현대카드',
          '06': '국민카드',
          '11': 'BC카드',
          '12': '삼성카드',
          '14': '신한카드',
          '16': '농협카드',
          '21': '해외 VISA카드',
          '22': '해외마스터카드',
          '23': '해외 JCB카드',
          '26': '중국은련',
          '32': '광주은행',
          '33': '전북은행',
          '34': '하나은행',
          '35': '산업카드',
          '41': 'NH',
          '43': '씨티은행',
          '44': '우리은행',
          '48': '신협체크',
          '51': '수협',
          '52': '제주은행',
          '54': 'MG새마을금고체크',
          '55': '케이뱅크',
          '56': '카카오뱅크',
          '71': '우체국체크',
          '95': '저축은행체크'
        },
        vBill: false
      }
    },
    beforeDestroy() {
      this.offModal();
    },
    methods: {
      onSelectBill(item) {
        this.request.user.post(`launchpack/card_bill/service/${this.detail.id}`, {'user_bill': item.id}).then(res=>{
          if(res.status === 200) {
            this.detail.service_order.bill.card_name = item.card_name;
            this.detail.service_order.bill.num = item.num;
          }
        });
      },
      onModal() {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      },
      offModal() {
        document.getElementsByTagName('html')[0].style.overflow = '';
      },
      getData() {
        this.request.user.get(`/launchpack/service/${this.$route.query.id}`).then(res=>{
          this.detail = res.data;
          this.detail.service_order.products.forEach(item=>{
            if(item.is_subscription) {
              if(this.serviceNames !== '') {
                this.serviceNames += '+';
              }
              this.serviceNames += item.category;
            }
          });

          /*this.billRegUrl = `https://launchpack.co.kr/bill_reg?name=${this.user.name}&phone=${this.user.phone}&email=${this.user.email}&goodname=정기결제&price=${this.detail.service_order.subscribe_price}&option_type=1`;*/
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 24px

  .box-monthly
    border-radius 12px
    background-color $sub2
    color white
    padding 16px

  .divider
  .divider-gray2
    width 100%
    height 1px
    margin-top 16px

  .divider
    background-color $sub5
  .divider-gray2
    background-color $gray2

  .card-account
    display flex
    justify-content space-between
    padding 16px 0
    border-bottom 1px solid $gray2
  .card-account:last-child
    border-bottom 0

  @media (max-width:1024px)
    .box-monthly
      padding 12px
    .panel-container
      margin-bottom 16px

</style>
<style lang="stylus">
  @media (max-width:1024px)
    .my-service-detail
      .sweet-modal.is-alert .sweet-content
        padding 0
      .sweet-modal.is-mobile-fullscreen
        border-radius 0 !important
        width 100% !important
</style>
